import ApplicationController from '../application_controller'

export default class extends ApplicationController {

  connect(){
    super.connect()
    this.addAction('keydown', 'shortcuts')
    //this.addAction('contextmenu', 'showNodeContextMenu')
    this.treeResize()
    this.initTreeWidth()
    this.initExpandedTree()
  }

  shortcuts(e){
  }

  initTreeWidth(){
    let width = sessionStorage.getItem(this.getStorageName('tree-width'))
    if (width != null) this.element.style.width = `${ width }px`
  }

  showNodeContextMenu(e){
    // return this.getController('context-menu').showNodeContextMenu(e)
    let li = e.target.closest('li')
    let input = li.querySelector('input')
    let selection = [input]
    let name = input.name
    const detail = {kind: 'item', originalEvent: e, selection, name}
    document.dispatchEvent(new CustomEvent('context-menu-click', {detail}))
    document.dispatchEvent(new CustomEvent(`${name}-context-menu-click`, {detail}))
  }

  treeResize(){
    let me = this

    $('#tree').resizable({
      handles: { e: '.tree-handler' },
      // animate: true,
      minWidth: 50,
      // helper: 'ui-resizable-helper',
      stop: (u, ui) => {
        sessionStorage.setItem(me.getStorageName('tree-width'), ui.size.width)
      }
    })
  }

  initExpandedTree(){
    // this.expanded            = sessionStorage.getItem(this.getStorageName("expanded-list")) == "all"
    //
    // if (this.expanded){
    //   this._expandAll(treeviewController)
    // }else{
    //   this._expandSeveralList(treeviewController, breadcrumbController)
    // }

    this._initScroll()
    //this._updateItemCount(treeviewController)
    this._selectCurrentItem()
    this._setFocusOnRegion()
  }

  toggleExpandTree(){
    let treeviewController = this.getController('treeview')
    this.expanded ? this._collapseAll(treeviewController) : this._expandAll(treeviewController)
  }

  // toggleNode(e){
  //   let li                 = e.target.closest('li')
  //   let treeviewController = this.getController('treeview')
  //
  //   if (li.classList.contains('expanded')){
  //     treeviewController.collapseNode(li)
  //   }else{
  //     treeviewController.expandNode(li)
  //   }
  //   //this._storageExpandedList(treeviewController)
  // }

  set expanded(value){
    this.data.set('expanded', value)
  }

  get expanded(){
    return JSON.parse(this.data.get('expanded'))
  }

  // Protected Methods #########################################################

  _expandSeveralList(treeviewController, breadcrumbController){
    let me = this
    let treeListLis = treeviewController.getNodes()
    let ancestors = breadcrumbController.ancestors
    let expandedLi = (sessionStorage.getItem(this.getStorageName('expanded-list')) || '').split(',')
    let collapsedLi = (sessionStorage.getItem(this.getStorageName('collapsed-list')) || '').split(',')

    if (expandedLi[0] == '' && expandedLi.length == 1) expandedLi = []
    expandedLi = _.merge(ancestors, expandedLi)

    if (collapsedLi[0] == '' && collapsedLi.length == 1) collapsedLi = []

    collapsedLi.forEach(function(id){
      if (_.includes(me.ancestors, id)) delete collapsedLi[id]
    })

    if ((expandedLi.length > collapsedLi.length) && (expandedLi.length > (treeListLis.length / 2))){
      treeListLis.forEach(x => x.classList.add('expanded'))
      collapsedLi.forEach(function(id){
        treeviewController.element.querySelector(`li input[value="${ id }"]`).closest('li').classList.remove('expanded')
      })
    } else {
      expandedLi.forEach(function(id){
        treeviewController.element.querySelector(`li input[value="${ id }"]`).closest('li').classList.add('expanded')
      })
    }
  }

  _expandAll(treeviewController){
    treeviewController.expandAll()
    sessionStorage.setItem(this.getStorageName('expanded-list'), 'all')
    sessionStorage.setItem(this.getStorageName('collapsed-list'), 'nothing')
    this.expanded = true
  }

  _collapseAll(treeviewController){
    treeviewController.collapseAll()
    sessionStorage.setItem(this.getStorageName('expanded-list'), [])
    sessionStorage.setItem(this.getStorageName('collapsed-list'), 'all')
    this.expanded = false
  }

  _storageExpandedList(treeviewController){
    // Get all collapsed ids and expanded ids
    let expandedLis = treeviewController.element.querySelectorAll('li.expanded')
    let collapsedLis = treeviewController.element.querySelectorAll('li:not(.expanded):not(.leaf)')
    let expandedIds = Array.prototype.map.call(expandedLis, e => e.querySelector('input').value)
    let collapsedIds = Array.prototype.map.call(collapsedLis, e => e.querySelector('input').value)

    // Save in session Storage
    sessionStorage.setItem(this.getStorageName('expanded-list'), expandedIds)
    sessionStorage.setItem(this.getStorageName('collapsed-list'), collapsedIds)
  }

  // Select current node in tree
  _selectCurrentItem(){
    if (document.getElementById('breadcrumb') == undefined) return 
    let currentNode = document.getElementById('breadcrumb').getAttribute('data-breadcrumb-current')
    //breadcrumbController.current
    let node = null

    if (currentNode != null && currentNode != 0){
      node = document.querySelector(`#tree li input[value="${ currentNode }"] + label`)
    } else {
      node = document.querySelector('#tree li input:first-child + label')
    }
    node.classList.add('current')
    setTimeout(function(){ node.click()}, 50) // To give time for loading
  }

  _initScroll(){
    let me = this
    let treeBody = this.element
    let scrollTop = sessionStorage.getItem(this.getStorageName('tree-scroll')) || 0

    treeBody.animate({ scrollTop: scrollTop }, 0)
    treeBody.scroll(function(){
      sessionStorage.setItem(me.getStorageName('tree-scroll'), $(this).scrollTop())
    })
  }

  _setFocusOnRegion(){
    this.element.focus()
  }

}
