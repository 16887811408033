export let designShortcutsMixin = {

  // Shortcuts function
  shortcuts(e){
    if (e.key == 'Escape') this._shortcutsCancelAction(e)
    if (e.target.tagName == 'INPUT' && ['text', 'search'].includes(e.target.type)) return false
    if (e.key == 'ArrowDown') this._shortcutsArrowDownAction(e)
    if (e.key == 'ArrowUp') this._shortcutsArrowUpAction(e)
    if (e.key == 'ArrowRight') this._shortcutsArrowRightAction(e)
    if (e.key == 'ArrowLeft') this._shortcutsArrowLeftAction(e)
    if (e.key == 'Enter') this._shortcutsEnterAction(e)
    if (e.key == '?') this._shortcutsShowShortcuts(e)
    if (e.key == 'E') this._shortcutsEditItem(e)
    if (e.key == 'V') this._shortcutsPasteItem(e)
    if (e.key == 'C') this._shortcutsCopyItem(e)
    if (e.key == 'X') this._shortcutsCutItem(e)
    if (e.key == 'e') this._shortcutsEditLine(e)
    if (e.key == 'Delete') this._shortcutsDeleteLine(e)
    if (e.key.toLowerCase() == 'f') this._shortcutsSearchesAction(e)

    this._shortcutsAddElement(e)
  },

  _shortcutsAddElement(e){
    const shortcutAddKeys = {
      0: 'addPhase',
      1: 'addDossier',
      2: 'addSubassembly',
      3: 'addPart',
      4: 'addTransportStep',
      5: 'addEnergyConsumption'
    }

    let name = shortcutAddKeys[e.key]

    if (name != undefined && !e.ctrlKey){
      let toolbarController = this.getController('toolbar')
      let button = toolbarController.targets.find(name)

      if (button.getAttribute('disabled') == null) button.click()
    }
  },

  // Private Methods

  _shortcutsShowShortcuts(e){
    this.getController('interface').shortcutsBtnTarget.click()
  },

  _shortcutsArrowLeftAction(e){
    let checkedItem = this._getCheckedItem()
    e.ctrlKey ? this._shortcutsSwitchToLeftRegion(checkedItem) : this._shortcutsCollapseNode(checkedItem)
  },

  _shortcutsArrowRightAction(e){
    let checkedItem = this._getCheckedItem()
    e.ctrlKey ? this._shortcutsSwitchToRightRegion(checkedItem) : this._shortcutsExpandNode(checkedItem)
  },

  _shortcutsCollapseNode(checkedItem){
    let canCollapse = checkedItem.closest('li').classList.contains('expanded')
    if (canCollapse) checkedItem.nextElementSibling.querySelector('.caret').click()
  },

  _shortcutsExpandNode(checkedItem){
    let li = checkedItem.closest('li')
    let canExpand = !li.classList.contains('expanded')
    let notLeaf = !li.classList.contains('leaf')

    if (canExpand && notLeaf) checkedItem.nextElementSibling.querySelector('.caret').click()
  },

  _shortcutsToggleNode(checkedItem){
    let notLeaf = !checkedItem.closest('li').classList.contains('leaf')
    if (notLeaf) checkedItem.nextElementSibling.querySelector('.caret').click()
  },

  _shortcutsMoveSelectedItem(number){
    let checkedItem = this._getCheckedItem()
    if (checkedItem == null) return false

    let regionId = this._getRegionId(checkedItem)
    let lis = $(`#${ regionId } .view li:visible`)
    let currentLi = $(checkedItem.closest('li'))
    let currentInputId = currentLi.find('input').attr('id')
    let index = lis.toArray().findIndex(x => x == checkedItem.closest('li'))
    let nextLi = lis[index+number]

    if (nextLi != null) this._selectItem(nextLi.querySelector('input[type=radio], input[type=checkbox]'))
  },

  _shortcutsArrowDownAction(e){
    e.ctrlKey ? this._shortcutsSwitchToDownRegion() : this._shortcutsMoveSelectedItem(1)
  },

  _shortcutsArrowUpAction(e){
    e.ctrlKey ? this._shortcutsSwitchToUpRegion() : this._shortcutsMoveSelectedItem(-1)
  },

  _shortcutsSwitchToDownRegion(){
    this._shortcutsSelectFirstInputInRegion('search')
  },

  _shortcutsSelectFirstInputInRegion(regionName){
    if (document.querySelector(`#${ regionName } input[type=radio]`) != null){
      document.querySelector(`#${ regionName } input[type=radio]`).click()
      document.getElementById(regionName).focus()
    }
  },

  // Focus on correct search input field in design interface
  _shortcutsSearchesAction(e){
    let controllerName = ''
    switch (true){
    case this._isGlobalKeys(e):
      controllerName = 'interface'
      break
    case e.shiftKey:
      controllerName = 'search'
      break
    default:
      controllerName = 'library'
      break
    }
    this.getController(controllerName).focusOnSearchInput()
  },

  _shortcutsSwitchToUpRegion(){},

  _shortcutsEditItem(){
    this.getController('toolbar').editItem()
  },

  _shortcutsDeleteLine(){
    this.getController('toolbar').deleteItem()
  },

  _shortcutsCancelAction(e){
    let li = e.target.closest('li') || e.target
    let cancelBtn = li.querySelector('.cancel')

    cancelBtn.click()
  },

  _shortcutsEditLine(){
    this.getController('toolbar').editLineTarget.click()
  },

  _shortcutsCopyItem(){
    this.getController('toolbar').copyItemTarget.click()
  },

  _shortcutsPasteItem(){
    this.getController('toolbar').pasteItemTarget.click()
  },

  _shortcutsCutItem(){
    this.getController('toolbar').cutItemTarget.click()
  }

}
