import ApplicationController from '../application_controller'
import Loader from '../../src/lib/loader.js'

const debug = window.lcas_debug?.includes('treeview') // ?debug[treeview]=1

export default class TreeviewController extends ApplicationController {

  static values = {
    asyncReloadFrame: Boolean
  }

  connect(){
    super.connect()
    this.name = this.data.get('name') || 'node'
    if (this.element.classList.contains('c-treeview')) {
      this.treeview_class = 'c-treeview'
      this.item_class = 'c-treeview__item'
      this.caret_class = 'c-treeview__item-caret'
      this.current_class = 'c-treeview__item--current'
      this.current_label_selector = '.c-treeview__item--current > label'
      this.expanded_class = 'c-treeview__item--expanded'
      this.leaf_class = 'c-treeview__item--leaf'
      this.nocollapse_class = 'c-treeview__item--nocollapse'
      this.treeview_children_class = 'c-treeview__children'
    } else {
      this.treeview_class = 'treeview'
      this.caret_class = 'caret'
      this.item_class = 'list-group-item'
      this.current_label_selector = 'label.current'
      this.current_class = 'current'
      this.expanded_class = 'expanded'
      this.leaf_class = 'leaf'
      this.nocollapse_class = 'nocollapse'
      this.treeview_children_class = ''
    }

    this.addAction('keydown', 'onKeydown')
    this.addAction('turbo:load@document', 'onTurboLoad')
    this.installHandlers(this.element)
    this._replaceHtmlByCache()
    this.setupCurrentItem()
  }

  onTurboLoad(){
    this.setupCurrentItem()

    // Trigger frame reload async
    if (this.asyncReloadFrameValue) {
      let frame = this.element.closest('turbo-frame')
      frame.setAttribute('src', frame.getAttribute('src'))
    }
  }

  // See current_view_controller.js
  onCurrentItemChange(e) {
    const current_id = e.detail.ref_id
    this.setupCurrentItem(current_id)
  }

  setupCurrentItem(current_id = null, dispatch = true, expand = true){
    if (!current_id) current_id = document.body.dataset.currentId
    if (current_id) {
      for (let oldCurrent of this.element.querySelectorAll(this.current_label_selector)) {
        oldCurrent.parentElement.classList.remove(this.current_class)
      }
      for (let newCurrent of this.element.querySelectorAll(`.${this.item_class} > input[value="${current_id}"]`)) {
        newCurrent.parentElement.classList.add(this.current_class)
        if (dispatch) {
          document.dispatchEvent(new CustomEvent('current-item', {detail: {item: newCurrent.labels[0]}}))
        }
        if (expand && !this.isNodeExpanded(newCurrent) && !this.isNodeLeaf(newCurrent)) {
          this.expandNode(newCurrent)
        }
      }
    }

    for (let item of this.element.querySelectorAll('input[checked]')) {
      if (debug) console.log('treeview-controller: select %o', item)
      let label = item.labels[0]
      if (label) {
        label.click()
        label.scrollIntoView()
      }
    }
  }

  installHandlers(element){
    for (let node of element.querySelectorAll(`input[name=${this.name}] + label`)) {
      this.addAction('dblclick', 'enterIntoItem', node)
    }
    for (let node of element.querySelectorAll(`input[name=${this.name}] + label > .${this.caret_class}`)) {
      this.addAction('click', 'toggleNode', node)
    }
    for (let node of element.querySelectorAll(`input[name=${this.name}] + label > a[href]:not(.c-treeview__ico-popover)`)) {
      this.addAction('click', 'expandNode', node)
    }
  }

  showNodeContextMenu(e){
    return this.getController('context-menu').showNodeContextMenu(e)
  }

  getCurrentItem(){
    let label = this.element.querySelector(this.current_label_selector)
    return label ? Object.assign({}, label.dataset, label.control.dataset) : {}
  }

  getCurrentInput(){
    let label = this.element.querySelector(this.current_label_selector)
    return label ? label.control : null
  }

  onKeydown(e){
    if (this.isInputKeydown(e)) return

    if (['ArrowRight', 'ArrowLeft'].includes(e.key)) {
      if (e.ctrlKey || e.shiftKey || e.metaKey || e.altKey) return
      e.stopPropagation()
      if (e.key == 'ArrowRight') this._shortcutsExpandNode(document.activeElement)
      if (e.key == 'ArrowLeft') this._shortcutsCollapseNode(document.activeElement)
      return
    }

    if (e.key == 'Enter' && !e.ctrlKey && !e.shiftKey && !e.metaKey && !e.altKey) {
      e.preventDefault()
      e.stopPropagation()
      return this.enterIntoItem(e)
    }
  }

  enterIntoItem(e){
    e.target.closest('li').querySelector('a').click()
  }

  toggleNode(event_label_or_input){
    const label_or_input = this.getNodeFromEvent(event_label_or_input)

    if (this.isNodeExpanded(label_or_input)){
      this.collapseNode(label_or_input)
    } else {
      this.expandNode(label_or_input)
    }
  }

  isNodeExpanded(label_or_input){
    const li = label_or_input.closest('li')
    return li.classList.contains(this.expanded_class)
  }

  isNodeLeaf(label_or_input){
    const li = label_or_input.closest('li')
    return li.classList.contains(this.leaf_class)
  }

  getLoader(label) {
    let loader = label.treeview_loader
    if (!loader) {
      loader = new Loader(label)
      label.treeview_loader = loader
    }
    return loader
  }

  collapseNode(label_or_input){
    if (this.isNodeLeaf(label_or_input)) return

    const label = label_or_input.labels ? label_or_input.labels[0] : label_or_input
    const input = label_or_input.control ? label_or_input.control : label_or_input
    const id = label.getAttribute('data-id')
    const li = label.closest('li')
    const loader = this.getLoader(label)
    const remove_param = label.dataset.treeviewCollapseFramePersistRemoveParam

    if (li.classList.contains(this.nocollapse_class)) return
    li.classList.remove(this.expanded_class)
    li.querySelectorAll('li').forEach(x => x.classList.remove(this.expanded_class))
    this.expandCollapseAjax(label, id, false)

    // Remove param on next frame load if specified
    const frame_persist = remove_param && label.closest('[data-controller~="frame-persist-query"]')
    if (remove_param && frame_persist) {
      if (frame_persist.dataset.framePersistQueryRemoveParamsValue) {
        frame_persist.dataset.framePersistQueryRemoveParamsValue += '&' + remove_param
      } else {
        frame_persist.dataset.framePersistQueryRemoveParamsValue = remove_param
      }
    }

    li.classList.remove('c-treeview--loading')
    loader.disable()
  }

  expandNode(event_label_or_input) {
    const label_or_input = this.getNodeFromEvent(event_label_or_input)
    if (this.isNodeLeaf(label_or_input)) return
    if (this.isNodeExpanded(label_or_input)) return

    const label = label_or_input.labels ? label_or_input.labels[0] : label_or_input
    const input = label_or_input.control ? label_or_input.control : label_or_input
    const id = label.dataset.id || input.value
    const li = label.closest('li')
    const loader = this.getLoader(label)
    const is_static = label.getAttribute('data-static')

    if (!id) {
      console.error('Missing item id (%o, %o) when expanding tree', label.dataset.id, input.value, label, input)
    }

    li.classList.add('c-treeview--loading')
    loader.enable()
    if (is_static || _.includes(this.visited, id)) {
      li.classList.remove('c-treeview--loading')
      loader.disable()
      li.classList.add(this.expanded_class)
      this.expandCollapseAjax(li, id, true)
    } else {
      this.loadChildren(label)
        .done((htmlRecords) => { if (htmlRecords) this.insertHtml(htmlRecords, li) })
        .done(() => { li.classList.remove('c-treeview--loading') })
        .done(() => { loader.disable() })
        .done(() => { li.classList.add(this.expanded_class) })
        .done(() => { this.expandCollapseAjax(li, id, true) })
        .done(() => { this.cacheHtmlTree(li) })
    }
  }

  getNodeFromEvent(event_or_node) {
    let node = event_or_node.target || event_or_node
    if (node.tagName == 'INPUT' || node.tagName == 'LABEL') return node
    return node.closest('label')
  }

  cacheHtmlTree(li){
    let storeInSession = false //this.getController('region').isLibraryRegionFocused()
    let treeview = li.closest(`.${this.treeview_class}`)
    let trieviewParent = treeview.closest('div')

    if (storeInSession) sessionStorage.setItem(this.getStorageName(this._getTreeViewStorageName(treeview)), trieviewParent.innerHTML)
    return true
  }

  removeCacheHtmlTree(treeview){
    sessionStorage.removeItem(this.getStorageName(this._getTreeViewStorageName(treeview)))
  }

  insertHtml(htmlRecords, li){
    let ol = li.querySelector('ol')
    if (ol) ol.remove()
    li.insertAdjacentHTML('beforeEnd', `<ol class="${this.treeview_children_class}">${ htmlRecords }</ol>`)
    this.installHandlers(li.lastElementChild)
  }

  expandCollapseAjax(node, id, expanded){
    let url = this.findExpandUrl(node, id)
    if (!url) {
      let res = $.Deferred()
      res.resolve(null)
      return res
    }
    return $.get({ url: `${ url }expanded=${ expanded }` })
  }

  loadChildren(node){
    let level = node.getAttribute('data-level')
    let indent = node.getAttribute('data-indent')
    let type = node.getAttribute('data-type')
    let id = node.getAttribute('data-id')
    let url = this.findNodesUrl(node, id)

    if (!url) {
      // If children are not already loaded, simulate a click to open the tree.
      if (!node.parentElement.querySelector(`.${this.treeview_children_class}`)) {
        let a = node.querySelector('a')
        if (a) a.click()
      }

      let res = $.Deferred()
      res.resolve(null)
      return res
    }

    this.visited = id
    return $.get({ url: `${ url }level=${ level }&indent=${ indent }&type=${ type }&name=${this.name}`, dataType: 'html'})
  }

  findNodesUrl(node, id){
    let par = node.closest('[data-treeview-nodes-url]')
    if (par) {
      var url = par.dataset.treeviewNodesUrl
      url += url.includes('?') ? '&' : '?'

      return url.replace(':id', id).replace('~id', id)
    } else if (this.url) {
      let urlAr = this.url.split('?')
      let url = urlAr[0]
      let urlQuery = urlAr[1]

      return `${ url }/${ id }/nodes?${ urlQuery }&`
    }
    return null
  }

  findExpandUrl(node, id){
    let par = node.closest('[data-treeview-expand-url]')
    if (par) {
      var url = par.dataset.treeviewExpandUrl
      url += url.includes('?') ? '&' : '?'

      return url.replace(':id', id).replace('~id', id)
    } else if (this.url) {
      let urlAr = this.url.split('?')
      let url = urlAr[0]
      let urlQuery = urlAr[1]

      return `${ url }/${ id }/expand?`
    }
    return null
  }

  get url(){
    return this.data.get('url')
  }

  get visited(){
    return JSON.parse(this.data.get('visited')) || []
  }

  set visited(id){
    let visited = this.visited
    visited.push(id)
    this.data.set('visited', JSON.stringify(visited))
  }

  // Privates Methods

  // Replace html by cache if cache exist
  _replaceHtmlByCache(){
    let parentHtml = this.element.closest('div')
    let storageHtml = sessionStorage.getItem(this.getStorageName(this._getTreeViewStorageName()))

    if (storageHtml != null) parentHtml.innerHTML = storageHtml
  }

  _getTreeViewStorageName(treeview){
    treeview = treeview || this.element
    return `library-${ Array.prototype.join.call(treeview.classList,'-') }`
  }

  _shortcutsCollapseNode(checkedItem){
    let canCollapse = checkedItem.closest('li').classList.contains(this.expanded_class)
    if (canCollapse) checkedItem.nextElementSibling.querySelector(`.${this.caret_class}`).click()
  }

  _shortcutsExpandNode(checkedItem){
    let li = checkedItem.closest('li')
    let canExpand = !li.classList.contains(this.expanded_class)
    let notLeaf = !li.classList.contains(this.leaf_class)

    if (canExpand && notLeaf) checkedItem.nextElementSibling.querySelector(`.${this.caret_class}`).click()
  }

}
