import ToolbarBaseController from '../utils/toolbar_base_controller'
import DesignActions from '../../src/design/actions.js'

let debug = false

export default class DesignToolbar extends ToolbarBaseController {
  static targets = ['display', 'displayPopoverMenu',
    // Add operations
    'addPhase', 'addDossier', 'addSubassembly', 'addPart', 'addTransportStep',
    'addEnergyConsumption',
    // Actions
    'editLine', 'editItem', 'deleteItem', 'copyItem', 'cutItem', 'pasteItem',
    'fixBroken', 'updateModules', 'fixBroken']

  connect(){
    super.connect()

    this.debug = debug

    let actions = new DesignActions(this, [], {toolbar: 'node'})
    actions.updateActions({
      'editLine':      this.editLineTarget,
      'editItem':      this.editItemTarget,
      'deleteItem':    this.deleteItemTarget,
      'copyItem':      this.copyItemTarget,
      'cutItem':       this.cutItemTarget,
      'pasteItem':     this.pasteItemTarget,
      'fixBroken':     this.hasFixBrokenTarget ? this.fixBrokenTarget : null,
      'updateModules': this.hasUpdateModulesTarget ? this.updateModulesTarget : null,

      'addPhase':             this.addPhaseTarget,
      'addDossier':           this.addDossierTarget,
      'addSubassembly':       this.addSubassemblyTarget,
      'addPart':              this.addPartTarget,
      'addTransportStep':     this.addTransportStepTarget,
      'addEnergyConsumption': this.addEnergyConsumptionTarget,
    })

    this.bindActions(actions)
  }

  tableviewOptionsMenuToggle(){
    this.displayPopoverMenuTargets[0].classList.toggle('hidden')
  }

  editItem(){
    this._updateContextMenuAction().editItemTarget.click()
  }

  copyItem(){
    this._updateContextMenuAction().copyItemTarget.click()
  }

  cutItem(){
    this._updateContextMenuAction().cutItemTarget.click()
  }

  pasteItem(){
    this._updateContextMenuAction().pasteItemTarget.click()
  }

  replaceItem(){
    this._updateContextMenuAction(false).replaceItemTarget.click()
  }

  compareItem(){
    this._updateContextMenuAction(false).compareItemTarget.click()
  }

}
