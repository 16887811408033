import BaseActions from '../base_actions.js'

export default class AdminActions extends BaseActions {

  editLine(context){
    let label = document.querySelector('input[name=node]:checked + label')
    let item_id = document.querySelector('input[name=node]:checked').value
    this.helperEditLine(label, {
      title: I18n.t('admin.actions.edit_line.name'),
      frame_id: `edit-line-${item_id}`,
      frame_url: Routes.edit_line_admin_user_path(
        item_id,
        {frame: `edit-line-${item_id}`})
    })
  }

  updateAction(actname,action){
  }

}
