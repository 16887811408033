import ContextMenuBaseController from '../utils/context_menu_base_controller'
import DesignActions from '../../src/design/actions.js'

const debug = true

export default class extends ContextMenuBaseController {
  static targets = ['editLine', 'editItem', 'deleteItem', 'copyItem', 'cutItem',
    'pasteItem', 'reinitializeItem', 'infoItem', 'replaceItem', 'compareItem',
    'findInLibrary', 'fixBroken', 'updateModules', 'copyRefId']

  connect() {
    super.connect()
    this.names = ['node']

    const actions = new DesignActions(this, [], {menu: 'node'})
    actions.updateActions({
      'deleteItem':    this.deleteItemTarget,
      'copyItem':      this.copyItemTarget,
      'cutItem':       this.cutItemTarget,
      'pasteItem':     this.pasteItemTarget,
      'editLine':      this.editLineTarget,
      'infoItem':      this.infoItemTarget,
      'replaceItem':   this.replaceItemTarget,
      'fixBroken':     this.hasFixBrokenTarget ? this.fixBrokenTarget : null,
      'updateModules': this.hasUpdateModulesTarget ? this.updateModulesTarget : null,
      'copyRefId':      this.copyRefIdTarget,
    })

    this.bindActions(actions)
  }

  filterContextMenuClick(e) {
    return e.detail.name == 'node'
  }

  addItemToComparison(e){
    let selectedItem = this.getSelectedItems()[0].getAttribute('id')
    let moduleId = selectedItem.replace('Db::Module_', '').replace('Db::Flow_', '').replace('node_', '')
    let comparisonURL = e.target.closest('a').getAttribute('data-url')
    let newURL = comparisonURL.replace(':id', moduleId)

    window.location.href = newURL
  }

  findInLibrary(e){
    const selectedItem = this.getSelectedItems()[0]
    const itemExtId = selectedItem.dataset.dbItemExtId
    const itemId = selectedItem.dataset.dbItemId
    const categoryId = selectedItem.dataset.categoryId
    const typeName = selectedItem.dataset.type
    const library = document.querySelector('turbo-frame#design-library')
    if (debug) console.log('selectedItem = %o, categoryId = %o, library = %o', selectedItem, categoryId, library)
    let src = library.src
    if (!src.includes('?')) src = `${src}?`
    src = `${src}&opened_category_ids[]=${categoryId}`
    src = `${src}&selected_item_ext_id=${itemExtId}`
    src = `${src}&selected_item_id=${itemId}`
    src = `${src}&tab=${typeName}s`
    library.src = src
  }

  showNodeContextMenu(e){
    if (!this._handleSelection(e)) return false
    let menu = document.getElementById('node-context-menu')
    this._showMenu(menu)
    this.updateActions()
    this._placeMenu(e.clientX, e.clientY, menu)
  }

  updateActions(){
    let menuItems = [
      this.editLineTarget,
      this.editItemTarget,
      this.copyItemTarget,
      this.cutItemTarget,
      this.infoItemTarget,
      this.replaceItemTarget,
      this.hasCompareItemTarget ? this.compareItemTarget : null,
      this.findInLibraryTarget
    ].filter(x => x)

    // Actions restricted for admins
    let adminMenuItems = [
      this.reinitializeItemTarget,
      this.copyRefIdTarget,
    ].filter(x => x)

    this._disableMenuItems(menuItems)

    let selectedItems = this.getSelectedItems()
    if (selectedItems.length > 0) {
      this._enableMenuItems(menuItems)
      let values = Array.prototype.map.call(selectedItems, e => e.value)
      let node = selectedItems[0]
      let id = node.getAttribute('value')
      let type = node.getAttribute('data-type')

      this.role_true_admin = JSON.parse(document.body.dataset.lcasRoleTrueAdmin || 'false')
      if (!this.role_true_admin) {
        this._hideMenuItems(adminMenuItems)
      } else {
        this._enableMenuItems(adminMenuItems)
        this.reinitializeItemTarget.setAttribute('href', this._buildMultiUrl(values, 'reinitialize'))
      }

      if (selectedItems.length > 1){
        this.editItemTarget.setAttribute('href', this._buildMultiUrl(values, 'multi_edit'))
      } else {
        this.editItemTarget.setAttribute('href', this._buildMakeUrl(type, id, 'edit') )
      }

      this.cutItemTarget.setAttribute('href', this._buildMultiUrl(values, 'cut'))
      if (!['module', 'flow'].includes(type)){
        this.replaceItemTarget.classList.add('disabled')
        if (this.hasCompareItemTarget) this.compareItemTarget.classList.add('disabled')
        this.findInLibraryTarget.classList.add('disabled')
      }

      if (type === 'module'){
        const db_id = encodeURIComponent(node.getAttribute('data-db-item-id'))
        const case_study_id = encodeURIComponent(this.getRootItem().id)
        let infoUrl = `/database/${ _.pluralize(type) }/${ db_id }?case_study_ref_id=${case_study_id}`
        this.infoItemTarget.setAttribute('href', infoUrl)
        this.infoItemTarget.setAttribute('target', '_blank')
      } else {
        this.infoItemTarget.classList.add('disabled')
      }
    }

    this.pasteItemTarget.setAttribute('href', this._buildMultiUrl([], 'paste'))
  }

  handleShortcuts(e){
    if (this.isInputKeydown(e)) return
    this.updateActions()

    if (e.key == 'e' && !e.ctrlKey && !e.shiftKey && !e.metaKey && !e.altKey) {
      return this._activateMenuItem(e, this.editLineTarget)
    }

    if (e.key == 'E' && !e.ctrlKey && e.shiftKey && !e.metaKey && !e.altKey) {
      return this._activateMenuItem(e, this.editItemTarget)
    }

    if (e.key == 'C' && !e.ctrlKey && e.shiftKey && !e.metaKey && !e.altKey) {
      return this._activateMenuItem(e, this.copyItemTarget)
    }

    if (e.key == 'X' && !e.ctrlKey && e.shiftKey && !e.metaKey && !e.altKey) {
      return this._activateMenuItem(e, this.cutItemTarget)
    }

    if (e.key == 'Delete' && !e.ctrlKey && !e.shiftKey && !e.metaKey && !e.altKey) {
      return this._activateMenuItem(e, this.deleteItemTarget)
    }

    return false
  }

  getRootItem(){
    let interfaceController = this.getController('interface')

    return JSON.parse(interfaceController.data.get('root-item'))
  }
}
