import SelectionMixin from '../concerns/selection_mixin'
import ApplicationController from '../application_controller'

// Context menus
// =============
//
// Context menus are defined in different places:
// - HTML markup of the menu, linked to the menu javascript controller
// - the menu javascript controller inheriting ContextMenuBaseController
// - another widget or stimulus action calling the menu
//
// Markup - define your menu items
// -------------------------------
//
// You must define the root component and your menus below it with the hidden
// class enabled by default (so your menus will not show). The main menu is
// going to be the first with the class 'context-menu'
//
// .context-menus{'data-controller' => 'your-context-menu'}
//
//   .dropdown-menu.hidden.context-menu
//     = ui_link ...
//     .dropdown-divider
//     ...
//
//   #your-submenu-id.dropdown-menu.hidden.context-menu
//     ...
//
// See existing menus as an example
//
// Your menu controller
// --------------------
//
// You must inherit ContextMenuBaseController and must define:
//
// ### connect()
//
// Your initialization code, don't forget to call super.connect() first. You
// should define this.names that is an array of item names (input name
// attribute) that the context menu operates on.
//
// ### updateActions()
//
// updateActions() method is called when the menu is opened or triggered and it
// should update the href attribute (or equivalent) for the menu items so
// clicking on them does the right thing.
//
// ### handleShortcuts(e)
//
// handleShortcuts(e) is called when a key press event is detected that the menu
// could act upon. If the event is handled, the function must return true, else
// it must return false (meaning that the key press did not trigger a shortcut
// in this menu).
//
// The _activateMenuItem(e, target) method takes the key press event and a menu
// item (a link element). If the menu item is visible and not disable a click is
// simulated on it and _activateMenuItem returns true. Else it returns false.
// When the menu item is activated, the event propagation is stopped and the
// default action is prevented.
//
// Calling your context menu
// -------------------------
//
// To show your context menu when a right-click happens, you must bind the
// `contextmenu` DOM event to a function which does the following:
//
//     this.getController('your-context-menu').showMenu(e)
//
// To handle keybindings from the context menu, you should bind the `keydown`
// DOM event to a function which does the following:
//
//     // stop handling key bindings if the context menu handled the event
//     if(this.getController('your-context-menu').handleShortcuts(e)) return
//
// Submenus
// --------
//
// Submenus can be defined following the main menu. They must have an `id`
// attribute set. The menu item which triggers the submenu must contains the
// following attribute:
//
//     data-context-menu-submenu": "#your-submenu-id"
//
// Creating links
// --------------
//
// The following utility functions are useful to create links in your
// controller:
//
//  _buildMakeUrl(type, id, action)
//  _buildLibraryUrl(type, id, action)
//  _buildMultiUrl(values, action, parentRef)
//
// if you use them, make sure you define a getRootItem() method in your
// controller that returns the root item id. Else, the body must have a
// data-root-id attribute

export default class ContextMenuBaseController extends ApplicationController {

  connect(){
    super.connect()
    this.names = []
    this.addAction('keydown@document', 'onKeyDown')
    for (let item of this.element.querySelectorAll(`[data-${this.identifier}-submenu]`)) {
      this.addAction('mouseenter', 'openSubmenu', item)
      this.addAction('click', 'openSubmenuClick', item)
      this.addAction('mouseleave', 'closeSubmenu', item)
    }
    for (let menu of this.element.querySelectorAll('.context-menu')) {
      this.addAction('mouseenter', 'setInside', menu)
      this.addAction('mouseleave', 'setOutside', menu)
    }

    this.addAction('click@document', 'hideContextMenus')
    this.addAction('selected@document', 'onItemSelected')
    this.addAction('current-view-changed@document', 'onCurrentItem')
    this.addAction('context-menu-click@document', 'onContextMenuClick')
    this.addAction('selected-item-keydown@document', 'onSelectedItemKeydown')
    this.menuKind ||= true
  }

  showMenu(e){
    e.preventDefault()
    e.stopPropagation()
    this.hideContextMenus()
    let menu = this.element.querySelector('.context-menu')
    this._showMenu(menu)
    if (this.actions) this.actions.update()
    if (this.updateActions) this.updateActions(menu)
    for (let elem of this.element.querySelectorAll('.context-menu > *')) {
      elem.dispatchEvent(new Event('context-menu-update'))
    }
    this.handleSeparators()
    this._placeMenu(e.clientX, e.clientY, menu)
  }

  handleSeparators(){
    for (const menu of this.element.querySelectorAll('.context-menu')) {
      let have_items = false
      let last_divider = null
      for (const item of menu.children) {
        if (item.classList.contains('dropdown-divider')) {
          if (last_divider) last_divider.classList.toggle('hidden', !have_items)
          last_divider = item
          item.classList.toggle('hidden', !have_items)
          have_items = false
        } else if (!item.classList.contains('hidden')) {
          have_items = true
          last_divider = null
        }
      }
      if (last_divider) last_divider.classList.toggle('hidden', !have_items)
    }
  }

  bindActions(actions){
    this.actions = actions

    const currentView = this.getController('current-view')
    if (currentView) actions.updateItems(null, currentView.currentItem)

    for (let _actname in actions.actions){
      let actname = _actname
      let target = actions.actions[actname]
      if (actions[actname] && target) {
        this[actname] = (e) => {
          e.preventDefault()
          actions[actname]({toolbar: false, menu: this.menuKind})
        }
        this.addAction('click', actname, target)
      }
    }
  }

  onContextMenuClick(e) {
    this.selection = e.detail.selection
    if (!this.filterContextMenuClick(e)) return
    this.showMenu(e.detail.originalEvent)
  }

  onSelectedItemKeydown(e) {
    if (!this.filterContextMenuClick(e)) return
    this.handleShortcuts(e.detail.originalEvent)
  }

  filterContextMenuClick(e){
    return true
  }

  onItemSelected(e){
    const currentView = this.getController('current-view')
    const currentItem = currentView ? currentView.currentItem : null
    if (this.actions) this.actions.updateItems({[e.detail.name]: e.detail.selection}, currentItem)
  }

  onCurrentItem(e){
    if (this.actions) this.actions.updateItems([], e.detail.item)
  }

  getCurrentItem(){
    return this.getController('treeview').getCurrentItem()
  }

  hideContextMenus(){
    document.querySelectorAll('.context-menu').forEach(x => x.classList.add('hidden'))
  }

  openSubmenu(e){
    return this._openCloseSubmenu(e.target, true)
  }

  openSubmenuClick(e){
    e.preventDefault()
    e.stopPropagation()
    return this._openCloseSubmenu(e.target.closest('a'), true)
  }

  closeSubmenu(e){
    return this._openCloseSubmenu(e.target, false)
  }

  setInside(e){
    e.target.contextMenuInside = true
    for (let activeItem of e.target.querySelectorAll('.active')) {
      this._openCloseSubmenu(activeItem, false)
    }
  }

  setOutside(e){
    e.target.contextMenuInside = false
  }

  openFormPopup(e){
    e.preventDefault()
    this.openMakeForm(e.target.closest('a').href, (form) => {})
  }

  onKeyDown(e) {
    const label = e.target.closest('label')
    if (this.names.includes(label?.control?.name)) this.handleShortcuts(e)
  }

  handleShortcuts(e){
  }

  // Protected Methods #########################################################

  _activateMenuItem(e, item){
    if (!item || item.classList.contains('hidden') || item.classList.contains('disabled')) {
      return false
    } else {
      e.stopPropagation()
      e.preventDefault()
      item.click()
      return true
    }
  }

  _openCloseSubmenu(target, open){
    let submenuSelector = target.getAttribute(`data-${this.identifier}-submenu`)
    let submenu = this.element.querySelector(submenuSelector) || this.element.ownerDocument.querySelector(submenuSelector)

    if (open) {
      if (submenu.contextmenuCloseTimeout) {
        clearTimeout(submenu.contextmenuCloseTimeout)
        submenu.contextmenuCloseTimeout = undefined
      } else {
        let pos = target.getBoundingClientRect()
        this._showMenu(submenu)
        this._placeMenu(pos.right, pos.top, submenu)
        target.classList.add('active')
      }
    } else {
      submenu.contextmenuCloseTimeout = setTimeout(() => {
        if (!submenu.contextMenuInside) {
          submenu.classList.add('hidden')
          target.classList.remove('active')
          submenu.contextmenuCloseTimeout = undefined
        }
      }, 100)
    }
  }

  _buildMakeUrl(type, id, action){
    let parentRefId = this.element.getAttribute('data-parent-ref-id')
    let root_ref_id = document.body.dataset.rootId || this.getRootItem().id
    let query = `?interface=${ this.getInterfaceName() }` +
      (root_ref_id == null ? '' : `&root_ref_id=${ root_ref_id }`) +
      (parentRefId == null ? '' : `&parent_ref_id=${ parentRefId }`)
    let url = _.compact(['/make', _.pluralize(type), id, action, query]).join('/')

    this.addTurboBlacklist(url)

    return url
  }

  _buildAdminMultiUrl(type, values, action){
    let itemsIds = '?' + values.map(x => `${type}_ids%5B%5D=${ x }`).join('&')
    let url = _.compact(['/admin',_.pluralize(type), action + itemsIds]).join('/')
    this.addTurboBlacklist(url)
    return url
  }

  _buildAdminUrl(type, id, action){
    let url = _.compact(['/admin', _.pluralize(type), id, action]).join('/')
    this.addTurboBlacklist(url)
    return url
  }


  _buildLibraryUrl(type, id, action){
    let root_ref_id = document.body.dataset.rootId || this.getRootItem().id
    let parentRefId = this.element.getAttribute('data-parent-ref-id')
    let query = `?interface=${ this.getInterfaceName() }` +
                      (parentRefId == null ? '' : `7parent_ref_id=${ parentRefId }`)
    let url = _.compact(['/case-studies', root_ref_id, 'design', _.pluralize(type), id, action, query]).join('/')

    this.addTurboBlacklist(url)

    return url
  }

  _buildMultiUrl(values, action, parentRef){
    let parentRefId = parentRef || this.element.getAttribute('data-parent-ref-id')
    let itemsIds = '?' + values.map(x => `item_ids%5B%5D=${ x }`).join('&')
    let root_ref_id = document.body.dataset.rootId || this.getRootItem().id
    let query = `&interface=${ this.getInterfaceName() }` +
                      (root_ref_id == null ? '' : `&root_ref_id=${ root_ref_id }`) +
                      (parentRefId == null ? '' : `&parent_ref_id=${ parentRefId }`)
    let url = _.compact(['/make/cs_items', action + itemsIds + query]).join('/')

    this.addTurboBlacklist(url)

    return url
  }

  // _handleSelection is the first thing to do when a contextmenu event is
  // fired. It ensures that the event is handled properly and that the clicked
  // element is selected.
  //
  // It can take an optional label (if the autodetection does not work) and in
  // case the label does not have a control associated, or in case the event
  // fires with shift or control key pressed, the function returns false and the
  // caller is expected to return and let the event bubble up.
  //
  // It must be called this way:
  //     if (!this._handleSelection(e)) return false

  _handleSelection(e, label){
    // if ctrl or maj don't press
    if (e.ctrlKey || e.shiftKey) return false

    label = label || e.target.closest('label')

    // The clicked label does not have a form input associated
    if (label && !label.control) return false

    let selectedItems = this.getSelectedItems()
    let selectedItemsLength = selectedItems.length

    if (label == null) {
      selectedItems.forEach(x => x.checked = false)
    } else {
      if (selectedItemsLength < 2) label.control.click()
    }

    e.preventDefault()
    return true
  }

  _showMenu(contextMenuNode){
    contextMenuNode.classList.remove('hidden')
  }

  _hideMenu(contextMenuNode){
    contextMenuNode.classList.add('hidden')
  }

  _hideEmptyMenu(){
    for (let menu of this.element.querySelectorAll('.context-menu')){
      if (menu.querySelector(':scope > :not(.hidden)') == null) {
        menu.classList.add('hidden')
      }
    }
  }

  // _disableTargets disables the context menu items and set their href to a
  // void URL
  _disableMenuItems(targs){
    targs.filter(x => !!x).forEach(function(x){
      x.classList.add('disabled')
    })
  }

  _hideMenuItems(targs){
    targs.filter(x => !!x).forEach(function(x){
      x.classList.add('disabled', 'hidden')
    })
  }

  // _enableMenuItems enables the context menu items and set their href to a
  // void URL
  _enableMenuItems(targs){
    targs.filter(x => !!x).forEach(function(x){
      x.classList.remove('disabled', 'hidden')
    })
  }

  _showMenuItems(targs, enable){
    targs.filter(x => !!x).forEach(function(x){
      x.classList.remove('hidden')
      if (enable) {
        x.classList.remove('disabled')
      } else if (enable !== undefined){
        x.classList.add('disabled')
      }
    })
  }

  // _placeMenu must be executed after selection is handled and calculates the
  // menu position and shows it.
  _placeMenu(clientX, clientY, contextMenuNode){
    let leftPosition = this._menuPosition(clientX, 'Width', 'scrollX', contextMenuNode)
    let topPosition = this._menuPosition(clientY, 'Height', 'scrollY', contextMenuNode)

    contextMenuNode.setAttribute('style',
      'position: absolute; '+
      `left: ${ leftPosition }px; `+
      `top: ${ topPosition }px;`)
  }

  _menuPosition(mouse, direction, scrollDir, contextMenu){
    let pmouse = parseInt(mouse)
    let win = parseInt(document.documentElement[`client${ direction }`])
    let scroll = parseInt(window[scrollDir])
    let menu = parseInt(contextMenu[`client${ direction }`])
    let position = mouse + scroll

    // opening menu would overflow the page
    if ((pmouse + menu) > win) {
      // If there is space before the cursor to open the menu
      if (menu < pmouse) {
        position = pmouse - menu
      } else {
        position = 0
      }
    }
    return position
  }

}
Object.assign(ContextMenuBaseController.prototype, SelectionMixin)
