import ApplicationController from '../application_controller'
import SelectionMixin from '../concerns/selection_mixin'
import { Turbo } from '@hotwired/turbo-rails'

export default class InterfaceController extends ApplicationController {

  connect(){
    super.connect()
    this.addAction('keydown', 'onKeydown')
    this.addTurboBlacklistedLinks()

    // for Design
    this.addAction('keydown', 'onKeydown')
    //this.addAction('click', 'selectItem')
    //this.addAction('contextmenu', 'showContextMenu')
    //this.addAction('click@document', 'clearMenu')
  }

  //setupNodeSelection(){
  //  document.querySelectorAll('input[name=node] + label').forEach(node => this.addAction('click', 'selectItem', node))
  //}

  onKeydown(e){
    if (e.key == 'Escape' && !e.ctrlKey && !e.shiftKey && !e.metaKey && !e.altKey) {
      e.stopPropagation()
      e.preventDefault()
      return this.cancelEditLineByKeyboard(e)
    }

    if (e.key == 'Enter' && !e.ctrlKey && !e.shiftKey && !e.metaKey && !e.altKey) {
      if (e.target.type == 'text') {
        return this.submitEditLineByKeyboard(e)
      } else {
        return this.enterIntoItem(e)
      }
    }

    let contextMenuController = this.getController('context-menu')
    if (contextMenuController && contextMenuController.handleShortcuts(e)) return

    let contextMenuContainerController = this.getController('context-menu-container')
    if (contextMenuContainerController && contextMenuContainerController.handleShortcuts(e)) return
  }

  sendSearch(e){
    if (e.key == 'Enter') {
      let globalSearchController = this.getController('global-search')
      let inputSearchTarget = globalSearchController.inputSearchTarget
      inputSearchTarget.value = e.target.value
      $('#modal-search').modal('toggle')
      inputSearchTarget.focus()
    }
  }

  enterIntoItem(e){
    let li = e.target.closest('li')
    if (li && !li.classList.contains('unselectable')){
      let aElement = li.querySelector('.t-cell.name a[href]')

      if (aElement == undefined){ return }
      let href = aElement.getAttribute('href')

      if (li.getAttribute('data-leaf') == 'false'){
        Turbo.visit(href, { action: 'replace' })
      } else {
        this.openMakeForm(href, (form) => {})
      }
    }
  }

  get version(){
    return this.data.get('version')
  }

  hideContextMenus(){
    let contextMenuController = this.getController('context-menu')
    if (contextMenuController != null) contextMenuController.hideContextMenus()
  }

  openFormPopup(e){
    e.preventDefault()
    this.openMakeForm(e.target.closest('a').href, (form) => {})
  }

  get rootItem(){
    return this.data.get('root-item')
  }

  get rootId(){
    let item = this.rootItem
    return item ? item.id : null
  }

  // ===========================================================================
  //
  // Line Editing (in place)
  //

  // Remove form in line to edit item
  cancelEditLine(e){
    e.preventDefault()

    let row = e.target.closest('.t-row, li')
    let rowDiv = e.target.closest('.t-row, label')
    let cellDivs = rowDiv.querySelectorAll('.t-cell')
    let inputs = rowDiv.querySelectorAll('input, select')

    rowDiv.setAttribute('data-edit-line', false)
    cellDivs.forEach(e => e.classList.remove('edit-size'))
    inputs.forEach(e => e.value = e.getAttribute('data-original-value'))
    row.classList.remove('unselectable')
    row.classList.remove('edit-item')
    row.closest('.region').classList.remove('edit-line')

    if (this.element.querySelectorAll('.t-row .edit-size').length == 0) this.element.classList.remove('edit-line')

    row.closest('.region').focus()
  }

  // Show form in line to edit item
  editLine(e){
    let label = document.querySelector('input[name=node]:checked + label')
    let row = label.closest('.t-row, li')
    let cellDivs = label.querySelectorAll('.t-cell')
    let nameInput = label.querySelector('input[type=text]')
    let nameValue = nameInput.value

    e.preventDefault()

    label.setAttribute('data-edit-line', true)
    label.closest('.region').classList.add('edit-line')
    cellDivs.forEach(e => e.classList.add('edit-size'))
    row.classList.add('unselectable')
    row.classList.add('edit-item')

    nameInput.focus()
    setTimeout(function(){ nameInput.value = nameValue }, 50)
  }

  cancelEditLineByKeyboard(e){
    let li = e.target.closest('li') || e.target
    let cancelBtn = li.querySelector('.cancel')

    if (cancelBtn) {
      e.preventDefault()
      e.stopPropagation()
      cancelBtn.click()
    }
  }

  submitEditLineByKeyboard(e){
    let li = e.target.closest('li') || e.target
    let submitBtn = li.querySelector('.btn-success')

    if (submitBtn) {
      e.preventDefault()
      e.stopPropagation()
      submitBtn.click()
    }
  }

  // clickEditLine(e){
  //   e.preventDefault()
  //
  //   if (e.target.closest('li').querySelector('input').checked) this.editLine(e)
  //   e.target.closest('li').querySelector('label').click()
  // }

  // ===========================================================================
  //
  // Handler (movable split between panes)
  //

  showHandler(e){
    if (e.target.classList.contains('tree-handler')){
      document.querySelector('.overview').classList.add('tree-handler-over')
    } else if (e.target.classList.contains('details-handler')){
      document.querySelector('.details').classList.add('details-handler-over')
      document.querySelector('.overview').classList.add('overview-details-over')
    } else if (e.target.classList.contains('library-handler')){
      document.querySelector('.library').classList.add('library-handler-over')
    } else if (e.target.classList.contains('library-comparison-handler')){
      document.querySelector('.pnl-body').classList.add('library-comparison-handler-over')
    } else {
      document.querySelector('.search').classList.add('search-handler-over')
    }
  }

  hideHandler(e){
    if (e.target.classList.contains('tree-handler')){
      document.querySelector('.overview').classList.remove('tree-handler-over')
    } else if (e.target.classList.contains('details-handler')){
      document.querySelector('.overview').classList.remove('details-handler-over')
      document.querySelector('.details').classList.remove('details-handler-over')
    } else if (e.target.classList.contains('library-handler')){
      document.querySelector('.library').classList.remove('library-handler-over')
    } else if (e.target.classList.contains('library-comparison-handler')){
      document.querySelector('.pnl-body').classList.remove('library-comparison-handler-over')
    } else {
      document.querySelector('.search').classList.remove('search-handler-over')
    }
  }

  preventDefault(e){
    e.preventDefault()
  }

  focusOnSearchInput(){
    document.querySelector('.navbar input[type=search]').focus()
  }

  get name(){
    return this.data.get('name')
  }
}
Object.assign(InterfaceController.prototype, SelectionMixin)
