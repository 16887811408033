// drop-target controller
// ======================
//
// This controller binds to folder elements and allow any draggable item to be
// dropped onto it (move action).
//
// Usage
// -----
//
// The drop target must have the following structure values defined
//
// - data-drop-target-ref-id-value: Must contain the refId of the drop target.
//   If not specified, the drop target must contain an INPUT element which value
//   is the ref_id. If no label, the current view value is taken
// - data-drop-target-type-class-value: Must contain the Ruby type class of the
//   drop target item. If not present, the drop target must contain a LABEL
//   which has a data-type-class attribute. If no label, the current view value
//   is taken
//
// The drop target will be associated with the containing label if it exists, or
// to the current element.
//
// Drop element
// ------------
//
// The element being dropped must be a label or contain a label containing
// either a data-id attribute with the element id being dropped, or associated
// with an input which value is the id being dropped. The label must contain
// also a data-type-class attribute with the type of object being dropped.
//

import ApplicationController from '../application_controller'
import DesignLibraryActions from '../../src/design/library_actions'

const debug = false

export default class DropTargetController extends ApplicationController {

  static values = {
    refId: String,
    typeClass: String
  }

  connect(){
    super.connect()
    $(this.element.querySelector('label') || this.element).droppable({
      'tolerance': 'pointer',
      'classes':   {
        'ui-droppable-active': 'drop-target-active',
        'ui-droppable-hover':  'drop-target-hover',
      },
      'accept': (elem) => this.accept(elem),
      'drop': (ev, ui) => this.onDrop(ev, ui),
      'over': (ev, ui) => this.onOver(ev, ui),
      'out':  (ev, ui) => this.onOut(ev, ui),
    })
  }

  getDraggedInfo(elem){
    if (!elem) return null
    const label = elem.tagName == 'LABEL' ? elem : elem.querySelector('label')
    if (!label) return null

    if (debug) console.log(label.dataset)

    return {
      id: label.dataset.id || label.control.value,
      typeClass: label.dataset.typeClass,
      dbItem: label.dataset.typeClass.startsWith('Db::')
    }
  }

  get ref_id(){
    if (this.hasRefIdValue) return this.refIdValue

    const input = this.element.querySelector('input')
    if (input) return input.value

    return this.getController('current-view').currentRefId
  }

  get type_class(){
    if (this.hasTypeClassValue) return this.typeClassValue

    const label = this.element.querySelector('label')
    if (label) return label.dataset.typeClass

    return this.getController('current-view').currentSpecificTypeClass
  }

  accept(elem){
    let accept = true
    const info = this.getDraggedInfo(elem[0])

    if (!info) accept = false
    else if (info.id == this.ref_id) accept = false
    else if (!this.isCompatible(info.typeClass)) accept = false

    if (debug) console.log('drop-target: %s', accept ? 'accept' : 'reject', elem, info)
    return accept
  }

  isCompatible(draggedType){
    let compatible
    switch (this.type_class){
    case 'Cs::Project':
    case 'Cs::Folder':
      compatible = ['Cs::Folder', 'Cs::CaseStudy'].includes(draggedType)
      break
    case 'Cs::Phase':
    case 'Cs::Container':
    case 'Cs::System':
      compatible = ['Cs::EnergyConsumption', 'Cs::TransportStep', 'Cs::Container', 'Cs::Module', 'Cs::Flow', 'Db::System', 'Db::Module', 'Db::Flow'].includes(draggedType)
      break
    default:
      compatible = false
      break
    }
    if (debug) console.log('drop-target: %s %s compatible with %s', this.type_class, compatible ? 'is' : 'is not', draggedType)
    return compatible
  }

  onOver(ev, ui){
    this.element.classList.add('drop-target-hover')
  }

  onOut(ev, ui){
    this.element.classList.remove('drop-target-hover')
  }

  onDrop(ev, ui){
    if (debug) console.log('drop-target: onDrop', ev, ui)
    this.element.classList.remove('drop-target-hover')
    let info = this.getDraggedInfo(ui.draggable[0])

    if (info.dbItem) {

      new DesignLibraryActions(this).addDbItemFromUrl({
        parent_ref_id: this.ref_id,
        db_item_id: info.id,
        type_class: info.typeClass
      })

    } else {
      let headers = new Headers()
      headers.append('x-csrf-token', $('meta[name="csrf-token"]').attr('content'))
      let url = `/make/cs_items/move?item_ids[]=${info.id}&parent_ref_id=${this.ref_id}`
      fetch(url, {
        method: 'POST',
        headers: headers,
      }).then(response => response.text()
      ).then(body => {
        window.turboReload()
      })
    }
  }
}

