import ContextMenuBaseController from '../utils/context_menu_base_controller'

export default class AdminContextMenuContainerController extends ContextMenuBaseController {
  static targets = [
    'addUser',
    'addDepartment',
    'addCompany',
    'addInstance',
  ]

  updateActions(){
    this.addUserTarget.setAttribute('href', this._buildMakeUrlAdmin('user', 'new'))
    this.addDepartmentTarget.setAttribute('href', this._buildMakeUrlAdmin('department', 'new'))
    this.addCompanyTarget.setAttribute('href', this._buildMakeUrlAdmin('company', 'new'))
    this.addInstanceTarget.setAttribute('href', this._buildMakeUrlAdmin('instance', 'new'))
  }

  _buildMakeUrlAdmin(type, action){
    let url = _.compact(['/admin', _.pluralize(type), action]).join('/')
    this.addTurboBlacklist(url)
    return url
  }


  handleShortcuts(e){
    if (this.isInputKeydown(e)) return
    this.updateActions()

    if (e.key == '0' && !e.ctrlKey && !e.shiftKey && !e.metaKey && !e.altKey) {
      return this._activateMenuItem(e, this.addProjectTarget)
    }

    if (e.key == '1' && !e.ctrlKey && !e.shiftKey && !e.metaKey && !e.altKey) {
      return this._activateMenuItem(e, this.addCaseStudyTarget)
    }

    if (e.key == '2' && !e.ctrlKey && !e.shiftKey && !e.metaKey && !e.altKey) {
      return this._activateMenuItem(e, this.addFolderTarget)
    }

    if ((e.key == 'V' || e.key == 'P') && !e.ctrlKey && e.shiftKey && !e.metaKey && !e.altKey) {
      return this._activateMenuItem(e, this.pasteTarget)
    }

    return false
  }
}
