import DesignController from '../design_controller'
import { libraryShortcutsMixin } from './shortcuts/library_shortcuts'
import SelectionMixin from '../concerns/selection_mixin'
import { init } from '../../src/ui_bibz'
import { LibraryPaneContextMenu } from 'actions'

export default class DesignLibraryController extends DesignController {

  connect(){
    super.connect()
    this.addAction('keydown', 'onKeydown')
    this.addAction('click', 'selectItem')
    this.addAction('contextmenu', 'showContextMenu')

    init(this.element)

    this.initLibraryWidth()
    this.initComparisonHeight()
    this.libraryResize()
    this.comparisonResize()
  }

  onKeydown(e){
    if (this.isInputKeydown(e)) return

    if (this.getController('design-context-menu-library').handleShortcuts(e)) return

    return this.getSelection('node').selectionKeydown(e)
  }

  showContextMenu(e){
    LibraryPaneContextMenu.show(e)
    //return this.getController('design-context-menu-library').showMenu(e)
  }

  toggleDatasetChart(e){
    let link = e.target.tagName == 'A' ? e.target : e.target.closest('a')
    let chart = window.comparisonChart
    let dataset = chart.config.data.datasets.filter(x => x.id == link.getAttribute('data-id'))[0]
    let hidden = dataset.hidden
    dataset.hidden = !hidden
    link.classList.toggle('block-line', !hidden)
    chart.update()
  }

  initLibraryWidth(){
    let width = sessionStorage.getItem(this.getStorageName('library-width'))
    if (width != null) this.element.style.width = `${ width }px`
  }

  initComparisonHeight(){
    let height = sessionStorage.getItem(this.getStorageName('library-comparison-height'))
    if (height != null && this.element.querySelector('.pnl-body')) this.element.querySelector('.pnl-body').style.height = `${ height }px`
  }

  libraryResize(){
    let me = this

    $('.library').resizable({
      handles: { w: '.library-handler' },
      stop: function(u, ui){
        sessionStorage.setItem(me.getStorageName('library-width'), ui.size.width)
      }
    })
  }

  comparisonResize(){
    let me = this
    $('.pnl-body').resizable({
      handles: { n: '.library-comparison-handler' },
      stop: function(u, ui){
        sessionStorage.setItem(me.getStorageName('library-comparison-height'), ui.size.height)
      }
    })
  }

  showNodeContextMenu(e){
    let contextMenuController = this.getController('design-context-menu-library')
    contextMenuController.showMenu(e)
  }

  addDbItem(e){
    e.preventDefault()
    let url = e.target.closest('li').querySelector('a').getAttribute('href')
    return this.addDbItemFromUrl(url)
  }

  addDbItemFromUrl(url, parent_ref_id = null){
    let href = window.location.href
    if (!parent_ref_id && href.split('/nodes/').length > 1){
      parent_ref_id = href.split('/nodes/')[1].split('?')[0]
    } else if (!parent_ref_id) {
      parent_ref_id = href.split('/root/')[1].split('?')[0]
    }
    let params = { parent_ref_id: parent_ref_id }
    let newUrl = addParamsToUrl(url, params.parent_ref_id == undefined ? {} : params)

    this.openMakeForm(newUrl, (form) => {})
  }

  getFirstInput(){
    return this.element.querySelector('.tab-content .active input[type=radio]')
  }

  focusOnSearchInput(){
    this.element.querySelector('.tab-content .active input[type=search]').focus()
  }

  removeHtmlStorage(e){
    let treeview = e.target.closest('.tab-pane').querySelector('.treeview')

    this.getController('treeview')?.removeCacheHtmlTree(treeview)
  }
}

Object.assign(DesignLibraryController.prototype, libraryShortcutsMixin)
Object.assign(DesignLibraryController.prototype, SelectionMixin)
