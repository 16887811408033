export let libraryShortcutsMixin = {

  _shortcutsSwitchToLeftRegion(checkedItem){
    let overviewFirstInput = document.querySelector('#overview input[type=radio]')
    if (overviewFirstInput == null){
      this.getController('tree').clickOnFirstInput()
    } else {
      this.getController('overview').clickOnFirstInput()
      document.getElementById('overview').focus()
    }
  },

  _selectFirtElementInActiveTab(){
    this.clickOnFirstInput()
  },

  _shortcutsSwitchToRightRegion(){
    this._selectFirtElementInActiveTab()
  },

  _shortcutsSwitchToDownRegion(){
    let activeLink = document.querySelector('#library .nav-tabs .nav-item .active')
    let nextTab = activeLink.closest('.nav-item').nextElementSibling
    if (nextTab){
      nextTab.querySelector('.nav-link').click()
    }
  },

  _shortcutsSwitchToUpRegion(){
    let activeLink = document.querySelector('#library .nav-tabs .nav-item .active')
    let previousTab = activeLink.closest('.nav-item').previousElementSibling
    if (previousTab) previousTab.querySelector('.nav-link').click()
  },

  _shortcutsEnterAction(e){
    let checkedItem = this._getCheckedItem()
    let linkEl = checkedItem.nextElementSibling.querySelector('a')

    if (linkEl != null){
      linkEl.click()
    } else {
      this._shortcutsToggleNode(checkedItem)
    }

  }

}
