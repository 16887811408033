import ToolbarBaseController from '../utils/toolbar_base_controller'

export default class AdminToolbarBaseController extends ToolbarBaseController {
  static targets = [
    'addUser',
    'addCompany',
    'addInstance',

    'editLine',
    'editItem',
    'deleteItem',

    'duplicate',

    'editExpirationDate',
    'editManager',
  ]

  updateAvailableActionsFromSelection(items){
    let toolbar = this.element
    toolbar.querySelectorAll('.item-actions .btn').forEach(btn => this._disableBtn(btn))
    if (items.length > 1) {
      toolbar.querySelectorAll('.item-actions .btn').forEach(btn => this._disableBtn(btn))
      let reactiv = document.querySelector('.reactiv_license')
      reactiv.querySelectorAll('.item-actions .btn').forEach(btn => this._enableBtn(btn))
    }
    else if (items.length == 1) {
      toolbar.querySelectorAll('.item-actions .btn').forEach(btn => this._enableBtn(btn))
    }
    else {
      toolbar.querySelectorAll('.item-actions .btn').forEach(btn => this._disableBtn(btn))
    }
  }
  editLine(e){
    return this.getController('interface').editLine(e)
  }

  editItem(e){
    e.preventDefault()
    this._updateContextMenuAction().editItemTarget.click()
  }

  deleteItem(e){
    e.preventDefault()
    this._updateContextMenuAction().deleteItemTarget.click()
  }

  duplicate(e){
    e.preventDefault()
    this._updateContextMenuAction().duplicateTarget.click()
  }
  
  editExpirationDate(e){
    e.preventDefault()
    this._updateContextMenuAction().editExpirationDateTarget.click()
  }
  
  editManager(e){
    e.preventDefault()
    this._updateContextMenuAction().editManagerTarget.click()
  }

  _updateContextMenuAction(updateActions = true){
    let contextMenuController = this.getController('admin-context-menu')
    if (updateActions) contextMenuController.updateActions()

    return contextMenuController
  }
}
