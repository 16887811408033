import ContextMenuBaseController from '../utils/context_menu_base_controller'
import ProjectActions from '../../src/project/actions.js'

export default class extends ContextMenuBaseController {
  static targets = [
    'addProject',
    'addCaseStudy',
    'addFolder',
    'paste',
    'import',
  ]

  static values = {
    teamManager: Boolean
  }

  connect() {
    super.connect()
    this.addAction('keydown@document', 'handleShortcuts')
    this.menuKind = 'current_node'
  }

  get teamManagerValue(){
    return this.data.get('team-manager') == 'true'
  }

  updateActions(){
    let cur = this.getController('treeview')?.getCurrentItem()
    let curInput = this.getController('treeview')?.getCurrentInput()

    this.addProjectTarget.setAttribute('href', this._buildMakeUrl('projects', 'new'))
    this.addCaseStudyTarget.setAttribute('href', this._buildMakeUrl('case-studies', 'new'))
    this.addFolderTarget.setAttribute('href', this._buildMakeUrl('folders', 'new'))
    this.pasteTarget.setAttribute('href', this._buildMultiUrl([], 'paste', cur?.id))

    if (cur?.id) this.importTarget.setAttribute('data-import-case-study-parent-ref-id', cur.id)

    let pa = new ProjectActions(this, curInput ? [curInput] : [], {menu: 'current_node'}, this.getController('current-view').currentItem, this.teamManagerValue)
    pa.updateActions({
      'addProject':   this.addProjectTarget,
      'addCaseStudy': this.addCaseStudyTarget,
      'addFolder':    this.addFolderTarget,
      'pasteItem':    this.pasteTarget,
      'import':       this.importTarget,

      'containerDividers': this.element.querySelectorAll('.dropdown-divider'),
    })

    this.bindActions(pa)
  }

  filterContextMenuClick(e){
    return e.detail.kind == 'container'
  }

  handleShortcuts(e){
    if (this.isInputKeydown(e)) return
    this.updateActions()

    if (e.key == '0' && !e.ctrlKey && !e.shiftKey && !e.metaKey && !e.altKey) {
      return this._activateMenuItem(e, this.addProjectTarget)
    }

    if (e.key == '1' && !e.ctrlKey && !e.shiftKey && !e.metaKey && !e.altKey) {
      return this._activateMenuItem(e, this.addCaseStudyTarget)
    }

    if (e.key == '2' && !e.ctrlKey && !e.shiftKey && !e.metaKey && !e.altKey) {
      return this._activateMenuItem(e, this.addFolderTarget)
    }

    if ((e.key == 'V' || e.key == 'P') && !e.ctrlKey && e.shiftKey && !e.metaKey && !e.altKey) {
      return this._activateMenuItem(e, this.pasteTarget)
    }

    return false
  }
}
