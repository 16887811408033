import ApplicationController from '../application_controller'

export default class extends ApplicationController {

  connect(){
    super.connect()
    this.addAction('click', 'setRegion')
    this.addAction('keydown', 'onKeydown')
    this._focusRegion(this.element.querySelector('.region'))
  }

  onKeydown(e){
    this.setRegion(e)
    this.shortcuts(e)
  }

  setRegion(e){
    let region = e.target.closest('.region')
    if (region) this.region = region.id
  }

  // Handle Ctrl-Left and Ctrl-Right to change active region
  shortcuts(e){
    if (e.shiftKey || e.metaKey || e.altKey) return
    if (this.isInputKeydown(e)) return

    console.log('region key event: %o', e)
    if (e.key == 'ArrowLeft') this._selectRegion(e, -1)
    if (e.key == 'ArrowRight') this._selectRegion(e, 1)
  }

  _selectRegion(e, num){
    e.stopPropagation()

    let currentRegion = e.target.closest('.region') || e.target.querySelector('.region')
    let regions = e.target.ownerDocument.querySelectorAll('.region')
    let regionIdx = Array.from(regions).findIndex(r => r == currentRegion)
    let newRegionIdx = regionIdx + num
    let newRegion = (newRegionIdx >= 0 && newRegionIdx < regions.length) ? regions[newRegionIdx] : null

    if (newRegion == null) {
      console.log('Cannot find next %d region', num)
      return
    }

    return this._focusRegion(newRegion)
  }

  _focusRegion(newRegion){
    let selector = newRegion.dataset.regionFocusSelector
    let focusable = (selector || !newRegion.hasAttribute('tabindex')) ?
      newRegion.querySelector(selector || '[tabindex], input:not(.hidden)') :
      newRegion
    if (!focusable) {
      console.log('Cannot find focusable for region %o', newRegion)
    }
    this.region = newRegion.id
    focusable.focus()
  }

  isOverviewRegionFocused(){
    return this.data.get('region') == 'overview'
  }

  isLibraryRegionFocused(){
    return this.data.get('region') == 'library'
  }

  setRegionOverview(){
    return this.region = 'overview'
  }

  setRegionTree(){
    return this.region = 'tree'
  }

  setRegionLibrary(){
    return this.region = 'library'
  }

  setRegionSearch(){
    return this.region = 'search'
  }

  get region(){
    return this.data.get('region')
  }

  set region(value){
    this.data.set('region', value)
  }

}
